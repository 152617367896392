/* global config, Promise */

'use strict';

angular.module('managerApp').controller('WebAppsController', ($scope, $rootScope,
        $location, $cookies, $routeParams, $mdDialog, toast, $_webapps, procedureTools) => {

//------------------------------------------------------------------------------

    const currentDir = 'app/webapps/';
    const path = $location.path();

    $scope.config = config;

//------------------------------------------------------------------------------

    $scope.firstRun = true;
    $scope.count = 0;
    $scope.items = [];
    $scope.newItem = {};

    $scope.pagination = {
        currentPage: 1,
        maxSize: 5,
        itemsPerPage: $cookies.get('pagination.webapps.itemsPerPage') || 10,
        totalItems: 0,
        pageChanged: () => {
            $cookies.put('pagination.webapps.itemsPerPage', $scope.pagination.itemsPerPage);
            $scope.getWebapps();
        }
    };

    $scope.offlineWebappsNb = 0;
    $scope.item_id = null;
    $scope.partnerId = $routeParams.id;
    $scope.webappId = $routeParams.id;

    $scope.webappPartners = [];

//------------------------------------------------------------------------------

    $scope.openMenu = function ($mdMenu, e) {
        $mdMenu.open(e);
    };

    $scope.showSearchPanel = () => {
        $rootScope.$broadcast('show-search-panel');
    };

    $rootScope.$on('get-webapps', (e) => {
        $scope.pagination.filters = e.targetScope.search.filters;
        $scope.getWebapps();
    });

//------------------------------------------------------------------------------    
const getPartnersWebApps = () => {
    let params = {
        partners: 1
    };
    const path_parts = $scope.currentPath.split("/")
        $scope.pagination.filters= path_parts.length>3 ? {PartnerId:path_parts[4]} :  {};
    if ($scope.pagination.filters) {
        for (let i in $scope.pagination.filters) {
            params[i] = $scope.pagination.filters[i];
        }
    }
    $_webapps.get(params, $scope.pagination.currentPage,
            $scope.pagination.itemsPerPage).then((data) => {
        $scope.count = data.count;
        $scope.items = data.items;
        $scope.pagination.totalItems = data.count;
        $scope.$apply();
    }).catch((err) => {
        $scope.error = err;
    });
};
    function getWebapps() {
        let params = {};
        if ($scope.pagination.filters) {
            for (let i in $scope.pagination.filters) {
                let value = $scope.pagination.filters[i];
                if (value !== '') {
                    if (i === 'webapp') {
                        if (value === null) {
                            delete params.ClientId;
                        } else {
                            params.ClientId = value._id;
                        }
                    } else {
                        params[i] = $scope.pagination.filters[i];
                    }
                }
            }
        }
        $_webapps.get(params, $scope.pagination.currentPage, $scope.pagination.itemsPerPage).then((data) => {
            $scope.count = data.count;
            $scope.items = data.items || [];
            $scope.pagination.totalItems = data.count;
            $scope.$apply();
        }).catch((err) => {
            toast.show(err, 'error');
        });
    }

    $scope.toggleActive = (item) => {
        if (confirm('Voulez-vous vraiment ' + (item.enabled ? 'dés' : '') + 'activer cette web app ?')) {            
            $_webapps.update({
                enabled: !item.enabled
            }, item._id).then(() => {
                toast.show('La web app "' + item.name + '" a bien été mise à jour.', 'success');
            }).catch(() => {
                toast.show('La web app "' + item.name + '" n\'a pas pu être mise à jour.', 'error');
                item.enabled = !item.enabled;
            });
        }
    };

    function updateTarget(target, attr, value) {
        for (let i = 0; i < $scope.items.length; i++) {
            let item = $scope.items[i];
            if (target === 'all') {
                item[attr] = value;
            } else {
                if (item._id === target) {
                    item[attr] = value;
                    break;
                }
            }
        }
    }

    function getWebapp(id) {
        if (!id) {
            return;
        }
        $_webapps.get({id: id}).then((webapp) => {
            $scope.webapp = webapp;
            getProcedures($scope.webappId);

        }).catch((err) => {
            toast.show('Web app introuvable', 'error').then(() => {
                $location.path('/webapps');
            });
        });
    }

    function getProcedures(id) {
        let params = {
            id: $scope.webappId
        };
        $_webapps.getproced(params, $scope.paginationproced.currentPage,
                $scope.paginationproced.itemsPerPage).then((data) => {
            $scope.proceduresCount = data.count;
            $scope.procedures = data.items;
            for (let i = 0; i < $scope.procedures.length; i++) {
                let procedure = $scope.procedures[i];
                procedure.origin = procedureTools.getOrigin(procedure);
                procedure.isHandled = procedureTools.isHandled(procedure);
                procedure.isHandledByUser = procedure.UserId === $rootScope.currentUser._id;
            }
            $scope.paginationproced.totalItems = data.count;
            $scope.$apply();
        });
    }

    $scope.submitWebappForm = (form) => {
        let webapp = $scope.webapp;
        if (form.$valid) {
            let promise = $scope.isEdit ? $_webapps.update(webapp, $scope.webappId) : $_webapps.add(webapp);
            promise.then(() => {
                toast.show('La web app a bien été ' + ($scope.isEdit ? 'mise à jour' : 'ajoutée.'), 'success', true, 2500).then(() => {
                    if (!$scope.isEdit) {
                        $scope.firstRun = true;
                        $location.path('/webapps');
                    }
                });
            }).catch((err) => {
                toast.show(err, 'error');
            });
        } else {
            toast.show('Veuillez remplir tous les champs obligatoires.', 'error', true);
        }
    };

    $scope.getPartners = (name = null, fb = null, ignoreCache = false) => {
        let cacheName = 'webappPartners.active';
        let params = {
            partners: 1,
            active: true
        };
        if (name !== null) {
            params.name = name;
            cacheName += '.' + name;
        }
        let data = JSON.parse(localStorage.getItem(cacheName));
        if (ignoreCache || !data) {
            $_webapps.get(params).then((data) => {
                localStorage.setItem(cacheName, JSON.stringify(data));
                $scope.webappPartners = data.items;
                $scope.$apply();
                if (typeof fb === 'function') {
                    fb(data.items);
                }
            }).catch((err) => {
                //
            });
        } else {
            $scope.webappPartners = data.items;
            if (typeof fb === 'function') {
                fb(data.items);
            }
        }//
    };

    $scope.showAddEditWebApp = (webapp = null) => {
        $mdDialog.show({
            templateUrl: currentDir + 'add.edit.webapp.html',
            parent: angular.element(document.body),
            fullscreen: true,
            locals: {
                webapp: webapp,
                getPartners: $scope.getPartners
            },
            controller: ($scope, toast, webapp, getPartners) => {
                $scope.isEdit = webapp !== null;
                $scope.webapp = webapp === null ? {} : webapp;

                getPartners(null, (data) => {
                    $scope.webappPartners = data;
                }, true);

                $scope.closeDialog = (confirm = false) => {
                    if (confirm) {
                        let promise = $scope.isEdit ? $_webapps.update($scope.webapp, $scope.webappId) : $_webapps.add($scope.webapp);
                        promise.then(() => {
                            $mdDialog.hide();
                            toast.show('La web app a bien été ' + ($scope.isEdit ? 'mise à jour' : 'ajoutée.'), 'success', true, 2500);
                        }).catch((err) => {
                            toast.show(err, 'error');
                        });
                    } else {
                        $mdDialog.hide();
                    }//
                };
            }
        }).then((answer) => {
            //
        }, () => {
            //
        });
    };

    $scope.showGenerateTokenPoup = (webapp = null) => {
        $mdDialog.show({
            templateUrl: currentDir + 'generate.token.html',
            parent: angular.element(document.body),
            fullscreen: true,
            locals: {
                webappId: webapp._id
            },
            controller: ($scope, toast, webappId) => {
                let token = $cookies.get(webappId + '_token');
                if (token) {
                    $scope.token = token;
                }
                $scope.generateToken = () => {
                    $_webapps.generateToken(webappId).then((res) => {
                        $cookies.put(webappId + '_token', res.token);
                        $scope.token = res.token;
                    });
                };
                $scope.closeDialog = () => {
                    $mdDialog.hide();
                };
            }
        }).then(() => {},() => {});
    };

    $scope.updateActivationCode = (item) => {
        $_webapps.updateActivationCode(item._id).then((webapp) => {
            item.activationCode = webapp.activationCode;
            toast.show("Le code d'activation de la web app a bien été mis à jour.", 'success');
        }).catch(() => {
            toast.show("Le code d'activation de la web app n'a pas pu être mis à jour.", 'error');
        });
    };

//------------------------------------------------------------------------------
   $scope.getWebapps = getWebapps;

    switch (path.substr(0, 14)) {
        case '/webapps':
            getWebapps();
            break;
        case '/webapps/partn':
            getPartnersWebApps();
            break;
        case '/webapps/add':
            break;
        default:
            getWebapp($scope.webappId);
    }

    $scope.getPartners();

});
